.NavMobile {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 30px);
  max-width: 360px;
  height: 100%;
  background: #171717;
  transition: .5s ease transform; }
  .NavMobile.closed {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
    transform: translateX(100%); }
  .NavMobile__content {
    overflow: auto;
    padding: 30px; }
