.Close {
  position: relative;
  width: 28px;
  height: 19px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: .5s ease transform; }
  .Close:hover .Burger__layer1 {
    transform: translateY(-3px); }
  .Close:hover .Burger__layer2 {
    transform: translateY(3px); }
  .Close:hover {
    transform: rotateZ(90deg); }
    .Close:hover .Burger__layer1 {
      top: 50%;
      transform: rotateZ(45deg) translateY(-50%) translateX(-1px); }
    .Close:hover .Burger__layer2 {
      top: 50%;
      transform: rotateZ(-45deg) translateY(-50%) translateX(1px); }
  .Close__layer1, .Close__layer2 {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 20px;
    transition: .2s ease all;
    position: absolute; }
  .Close__layer1 {
    top: 50%;
    transform: rotateZ(45deg) translateY(-50%) translateX(-1px); }
  .Close__layer2 {
    top: 50%;
    transform: rotateZ(-45deg) translateY(-50%) translateX(1px); }
