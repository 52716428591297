.Navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  z-index: 5; }
  .Navbar.scrolled {
    position: fixed; }
    .Navbar.scrolled .Navbar__bg {
      background: #171717; }
    .Navbar.scrolled .Navbar__layout {
      padding: 5px 0; }
    .Navbar.scrolled .Navbar__logo {
      max-width: 150px; }
    .Navbar.scrolled .Navbar__nav__item__content {
      padding: 10px 15px 2px; }
  .Navbar__bg {
    background: rgba(0, 0, 0, 0);
    transition: .2s ease background; }
  .Navbar__logo {
    display: block;
    max-width: 150px;
    height: auto;
    margin-right: 15px;
    transition: .2s ease max-width; }
    @media only screen and (min-width: 767px) {
      .Navbar__logo {
        max-width: 180px; } }
    @media only screen and (min-width: 1125px) {
      .Navbar__logo {
        max-width: 200px; } }
  .Navbar__layout {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    transition: .2s ease padding-bottom, .2s ease padding-top; }
    @media only screen and (min-width: 1124px) {
      .Navbar__layout {
        padding: 10px 0; } }
  .Navbar__left, .Navbar__right {
    align-items: center; }
  .Navbar__left {
    display: flex; }
  .Navbar__right {
    display: flex; }
    .Navbar__right .Burger {
      display: flex; }
      @media only screen and (min-width: 1124px) {
        .Navbar__right .Burger {
          display: none; } }
    .Navbar__right .SocialList {
      display: none; }
      @media only screen and (min-width: 1124px) {
        .Navbar__right .SocialList {
          display: inline-flex; } }
  .Navbar__nav {
    padding: 0;
    margin: 0;
    display: none;
    list-style-type: none; }
    @media only screen and (min-width: 1124px) {
      .Navbar__nav {
        display: flex; } }
    .Navbar__nav__item {
      display: flex;
      align-items: center;
      font-size: 15px; }
      @media only screen and (min-width: 767px) {
        .Navbar__nav__item {
          font-size: 16px; } }
      .Navbar__nav__item:hover .Navbar__nav__item__content {
        transform: translateY(-5px); }
        .Navbar__nav__item:hover .Navbar__nav__item__content:after {
          transform: translateY(0);
          opacity: 1; }
      .Navbar__nav__item a {
        display: block;
        color: #fff;
        text-decoration: none; }
        .Navbar__nav__item a.active:not(.no-active) {
          color: #ffd300; }
      .Navbar__nav__item .Navbar__nav__item__content {
        display: block;
        padding: 0 8px;
        transition: .2s ease transform;
        position: relative; }
        @media only screen and (min-width: 767px) {
          .Navbar__nav__item .Navbar__nav__item__content {
            padding: 10px 15px 4px; } }
        .Navbar__nav__item .Navbar__nav__item__content:after {
          content: '';
          display: block;
          height: 2px;
          margin-top: 2px;
          background-color: #ffd300;
          transition: .2s ease transform, .2s ease opacity;
          transform: translateY(5px);
          opacity: 0; }
  .Navbar__nav-mobile {
    display: none;
    margin: 0;
    display: block;
    flex-direction: column;
    list-style-type: none;
    padding: 20px 0 20px 0; }
    @media only screen and (min-width: 1124px) {
      .Navbar__nav-mobile {
        display: flex; } }
    .Navbar__nav-mobile__item {
      display: flex;
      align-items: center;
      margin-right: 30px; }
      .Navbar__nav-mobile__item a {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 12px 0;
        width: 100%;
        transition: .2s ease padding-left, .2s ease background-color; }
      .Navbar__nav-mobile__item:hover a {
        padding: 12px 0 12px 8px;
        background-color: rgba(255, 255, 255, 0.2); }
