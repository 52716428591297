.App {
  color: #fff;
  min-height: 100vh; }
  .App__bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -2;
    transition: 1s ease background; }
  .App p {
    font-size: 18px;
    line-height: 1.6em;
    text-align: left; }
