.MainContent {
  position: relative;
  min-height: 100vh;
  align-items: center;
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden; }
  .MainContent__content {
    box-sizing: border-box;
    width: 100%;
    padding: 80px 0;
    transition: 1s ease opacity, 1s ease transform;
    display: flex;
    min-height: 100vh; }
    .MainContent__content--center {
      align-items: center; }
    @media only screen and (min-width: 767px) {
      .MainContent__content {
        width: 80%;
        padding: 160px 0; } }
    @media only screen and (min-width: 1024px) {
      .MainContent__content {
        width: 65%; } }
    @media only screen and (min-width: 1350px) {
      .MainContent__content {
        width: 50%; } }
    .MainContent__content--no-bg {
      width: 100%; }
    .MainContent__content.hidden {
      opacity: 0;
      transform: translateY(10px); }
  .MainContent__image {
    position: fixed;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    transition: 1s ease opacity;
    opacity: 0.3;
    z-index: -1; }
    @media only screen and (min-width: 767px) {
      .MainContent__image {
        left: 50%;
        width: 50%;
        min-width: 600px;
        height: 100vh;
        opacity: 1; } }
    .MainContent__image.hidden {
      opacity: 0; }
