.Button {
  display: inline-flex;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.5);
  transition: .2s ease transform, .2s ease box-shadow;
  margin: 0 10px 10px 0;
  cursor: pointer; }
  .Button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 3px 3px rgba(0, 0, 0, 0.3); }
  .Button--yellow {
    background-color: #ffd300; }
    .Button--yellow a, .Button--yellow span {
      color: #171717; }
  .Button--blue-light {
    background-color: #47befb; }
    .Button--blue-light a, .Button--blue-light span {
      color: #171717; }
  .Button a, .Button span {
    padding: 0.7em 0.85em 0.5em;
    font-size: 22px;
    text-decoration: none; }
    @media only screen and (min-width: 420px) {
      .Button a, .Button span {
        font-size: 18px; } }
