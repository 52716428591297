.Events a {
  text-decoration: none; }

.Events__list {
  display: block;
  margin-top: 20px; }
  @media only screen and (min-width: 1024px) {
    .Events__list {
      display: flex;
      justify-content: space-between; } }
  .Events__list__event {
    margin-bottom: 50px; }
    @media only screen and (min-width: 1024px) {
      .Events__list__event {
        width: calc(50% - 20px); } }
  .Events__list__event-image,
  .Events__list .Heading {
    margin-bottom: 30px; }
  .Events__list__event-image {
    width: 100%;
    height: auto; }
