.SoundCloudProgress {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: .2s ease background-color; }
  .SoundCloudProgress:hover {
    background-color: rgba(255, 255, 255, 0.12); }
  .SoundCloudProgress__bar {
    height: 100%;
    position: absolute;
    top: 0;
    width: 0;
    background-color: rgba(255, 255, 255, 0.35); }
  .SoundCloudProgress__title {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 12px 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
