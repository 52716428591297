.SoundCloudPlay {
  position: relative;
  background-color: #ffd300;
  display: flex;
  font-size: 40px;
  width: 1em;
  height: 1em;
  cursor: pointer;
  transition: .2s ease background-color; }
  .SoundCloudPlay:not(.loading):hover {
    background-color: #ffe566; }
  .SoundCloudPlay.loading {
    cursor: default; }
    .SoundCloudPlay.loading .SoundCloudPlay__icon1 {
      opacity: 0.15; }
    .SoundCloudPlay.loading .SoundCloudPlay__icon2 {
      opacity: 0; }
  .SoundCloudPlay.playing .SoundCloudPlay__icon1 {
    border-width: 0.3em 0 0.3em 0.25em;
    border-color: #171717;
    left: 30%; }
  .SoundCloudPlay.playing .SoundCloudPlay__icon2 {
    opacity: 1; }
  .SoundCloudPlay__icon1, .SoundCloudPlay__icon2 {
    width: 0;
    height: 0;
    border-style: solid;
    transition: .8s ease all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .SoundCloudPlay__icon1 {
    border-width: 0.3em 0 0.3em 0.5em;
    border-color: transparent transparent transparent #171717;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .SoundCloudPlay__icon2 {
    opacity: 0;
    border-width: 0.3em 0 0.3em 0.25em;
    border-color: #171717;
    left: 70%; }
