.SocialIcon svg {
  display: block; }

.SocialIcon__item {
  border-radius: 4px;
  overflow: hidden;
  transition: .2s ease opacity; }
  .SocialIcon__item:hover {
    opacity: 0.8; }
  .SocialIcon__item svg {
    width: 34px;
    height: 34px; }
    .SocialIcon__item svg path {
      fill: #fff; }

.SocialIcon__facebook {
  background-color: #3C5A99; }

.SocialIcon__twitter {
  background-color: #38A1F3; }

.SocialIcon__instagram {
  background: #405de6;
  background: -moz-linear-gradient(-45deg, #405de6 0%, #c13584 33%, #fd1d1d 66%, #fcaf45 100%);
  background: -webkit-linear-gradient(-45deg, #405de6 0%, #c13584 33%, #fd1d1d 66%, #fcaf45 100%);
  background: linear-gradient(135deg, #405de6 0%, #c13584 33%, #fd1d1d 66%, #fcaf45 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#405de6', endColorstr='#fcaf45',GradientType=1 ); }

.SocialIcon__soundcloud {
  background: #f79810;
  background: -moz-linear-gradient(top, #f79810 0%, #f8310e 100%);
  background: -webkit-linear-gradient(top, #f79810 0%, #f8310e 100%);
  background: linear-gradient(to bottom, #f79810 0%, #f8310e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f79810', endColorstr='#f8310e',GradientType=0 ); }
