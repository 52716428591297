.Container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px; }
  @media only screen and (min-width: 574px) {
    .Container {
      max-width: calc(100% - 100px); } }
  .Container--full {
    max-width: 100%; }
  .Container--article {
    max-width: 840px;
    padding: 0 20px; }
    @media only screen and (min-width: 420px) {
      .Container--article {
        padding: 0; } }
  .Container--no-padding {
    padding: 0; }
