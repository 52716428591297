.SoundCloud {
  position: fixed;
  width: 100%;
  height: 40px;
  bottom: 0;
  z-index: 1;
  background-color: #2d355d;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
  transition: .5s ease bottom; }
  .SoundCloud.hidden {
    bottom: -45px; }
  .SoundCloud__player {
    display: flex; }
  .SoundCloud__default {
    height: 1px;
    opacity: 0;
    display: block; }
  .SoundCloud .Close {
    border: 10px solid transparent;
    box-sizing: content-box; }
