.Gallery__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-top: 15px; }
  @media only screen and (min-width: 574px) {
    .Gallery__list {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media only screen and (min-width: 767px) {
    .Gallery__list {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (min-width: 1024px) {
    .Gallery__list {
      grid-template-columns: 1fr 1fr 1fr; } }

.Gallery__column {
  cursor: pointer;
  transition: .3s ease box-shadow, .3s ease transform; }
  .Gallery__column:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transform: scale(1.05); }
  .Gallery__column img {
    display: block;
    max-width: 100%; }

.Gallery__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
  padding: 20px;
  box-sizing: border-box; }
  .Gallery__overlay__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: -1; }
  .Gallery__overlay__close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    padding: 20px;
    cursor: pointer;
    transition: .2s ease background-color; }
    .Gallery__overlay__close:hover {
      background-color: rgba(255, 255, 255, 0.15); }
    .Gallery__overlay__close polygon {
      fill: #fff; }
  .Gallery__overlay__next, .Gallery__overlay__prev {
    position: absolute;
    top: calc(50% - 30px);
    height: 60px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .Gallery__overlay__next svg, .Gallery__overlay__prev svg {
      display: block;
      width: 18px;
      height: auto;
      transition: .2s ease transform; }
    .Gallery__overlay__next polygon, .Gallery__overlay__prev polygon {
      fill: #fff; }
  .Gallery__overlay__next {
    right: 0; }
    .Gallery__overlay__next:hover svg {
      transform: translateX(4px); }
  .Gallery__overlay__prev {
    left: 0; }
    .Gallery__overlay__prev:hover svg {
      transform: translateX(-4px) rotateZ(-180deg); }
    .Gallery__overlay__prev svg {
      transform: rotateZ(-180deg); }
  .Gallery__overlay img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%; }
