.Burger {
  position: relative;
  width: 28px;
  height: 19px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: .5s ease transform; }
  .Burger:hover .Burger__layer1 {
    transform: translateY(-3px); }
  .Burger:hover .Burger__layer3 {
    transform: translateY(3px); }
  .Burger.open {
    transform: rotateZ(90deg); }
    .Burger.open .Burger__layer1,
    .Burger.open .Burger__layer2 {
      top: 50%;
      transform: rotateZ(45deg) translateY(-50%) translateX(-1px); }
    .Burger.open .Burger__layer2 {
      opacity: 0; }
    .Burger.open .Burger__layer3 {
      top: 50%;
      transform: rotateZ(-45deg) translateY(-50%) translateX(1px); }
  .Burger__layer1, .Burger__layer2, .Burger__layer3 {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 20px;
    transition: .2s ease all;
    position: absolute; }
  .Burger__layer1 {
    top: 0; }
  .Burger__layer2 {
    top: 50%;
    transform: translateY(-50%); }
  .Burger__layer3 {
    bottom: 0; }
