.Heading {
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  display: block;
  margin: 0.1em 0 0 0;
  line-height: 1em;
  text-decoration: none; }
  .Heading--shadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8); }
  .Heading--size0 {
    font-size: 27px; }
    @media only screen and (min-width: 420px) {
      .Heading--size0 {
        font-size: 36px; } }
    @media only screen and (min-width: 420px) {
      .Heading--size0 {
        font-size: 42px; } }
    @media only screen and (min-width: 574px) {
      .Heading--size0 {
        font-size: 48px; } }
    @media only screen and (min-width: 767px) {
      .Heading--size0 {
        font-size: 60px; } }
    .Heading--size0 .Heading__prefix {
      font-size: 0.4em; }
  .Heading--size1 {
    font-size: 23.4px; }
    @media only screen and (min-width: 420px) {
      .Heading--size1 {
        font-size: 31.2px; } }
    @media only screen and (min-width: 420px) {
      .Heading--size1 {
        font-size: 36.4px; } }
    @media only screen and (min-width: 574px) {
      .Heading--size1 {
        font-size: 41.6px; } }
    @media only screen and (min-width: 767px) {
      .Heading--size1 {
        font-size: 52px; } }
  .Heading--size2 {
    font-size: 20.7px; }
    @media only screen and (min-width: 420px) {
      .Heading--size2 {
        font-size: 27.6px; } }
    @media only screen and (min-width: 420px) {
      .Heading--size2 {
        font-size: 32.2px; } }
    @media only screen and (min-width: 574px) {
      .Heading--size2 {
        font-size: 36.8px; } }
    @media only screen and (min-width: 767px) {
      .Heading--size2 {
        font-size: 46px; } }
  .Heading--size3 {
    font-size: 18px; }
    @media only screen and (min-width: 420px) {
      .Heading--size3 {
        font-size: 24px; } }
    @media only screen and (min-width: 420px) {
      .Heading--size3 {
        font-size: 28px; } }
    @media only screen and (min-width: 574px) {
      .Heading--size3 {
        font-size: 32px; } }
    @media only screen and (min-width: 767px) {
      .Heading--size3 {
        font-size: 40px; } }
  .Heading--size4 {
    font-size: 14.4px; }
    @media only screen and (min-width: 420px) {
      .Heading--size4 {
        font-size: 19.2px; } }
    @media only screen and (min-width: 420px) {
      .Heading--size4 {
        font-size: 22.4px; } }
    @media only screen and (min-width: 574px) {
      .Heading--size4 {
        font-size: 25.6px; } }
    @media only screen and (min-width: 767px) {
      .Heading--size4 {
        font-size: 32px; } }
  .Heading--blue {
    color: #008aff; }
  .Heading--yellow {
    color: #ffd300; }
  .Heading--red {
    color: #ff1f39; }
  .Heading__prefix, .Heading__content {
    margin: 0; }
  .Heading__content {
    display: block; }
  .Heading__prefix {
    display: block; }
