.Home__logo {
  max-width: 100%;
  width: 400px; }

.Home__content {
  text-align: center;
  width: 100%; }

.Home .SocialList {
  margin-top: 20px; }
