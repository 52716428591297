.Lightbox__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 20px;
  box-sizing: border-box; }
  .Lightbox__overlay__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: -1; }
  .Lightbox__overlay__close {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer; }
  .Lightbox__overlay__next, .Lightbox__overlay__prev {
    position: absolute;
    top: calc(50% - 30px);
    height: 60px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .Lightbox__overlay__next svg, .Lightbox__overlay__prev svg {
      display: block;
      width: 18px;
      height: auto;
      transition: .2s ease transform; }
    .Lightbox__overlay__next polygon, .Lightbox__overlay__prev polygon {
      fill: #fff; }
  .Lightbox__overlay__next {
    right: 0; }
    .Lightbox__overlay__next:hover svg {
      transform: translateX(4px); }
  .Lightbox__overlay__prev {
    left: 0; }
    .Lightbox__overlay__prev:hover svg {
      transform: translateX(-4px) rotateZ(-180deg); }
    .Lightbox__overlay__prev svg {
      transform: rotateZ(-180deg); }
  .Lightbox__overlay img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%; }
