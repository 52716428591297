.Music__item {
  display: flex;
  margin: 40px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .Music__item__img {
    width: 100px;
    height: auto;
    display: block; }
  .Music__item__content {
    margin-left: 20px; }
  .Music__item__heading {
    margin: 5px 0;
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px; }

.Music__button {
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  padding: 0.7em 0.8em 0.5em;
  background-color: #ffd300;
  color: #171717;
  font-size: 20px;
  border-radius: 3px;
  margin-top: 10px;
  display: inline-block;
  transition: .2s ease transform; }
  .Music__button:hover {
    transform: translateY(-5px); }

.Music__link {
  color: #fff;
  margin-left: 0;
  padding: 0.7em 0.8em 0.5em 0;
  display: block; }
  @media only screen and (min-width: 650px) {
    .Music__link {
      margin-left: 10px;
      padding: 0.7em 0.8em 0.5em;
      display: inline-block; } }
